import React from "react";
import PendingIcon from "../../assets/images/multi-card-approval/pending_icon.svg";
import ApproveIcon from "../../assets/images/multi-card-approval/approveIcon.svg";
import RejectIcon from "../../assets/images/multi-card-approval/rejectedIcon.svg";
import WarningIcon from "../../assets/images/timekeeping_icons/warning_icon.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

export default function MultiCardTable(props) {
  const {
    tableDataArray,
    groupId,
    ptoStatus,
    checkedValues,
    handleMainCheckboxChange,
    startIndex,
    endIndex,
    handleIndividualCheckboxChange,
    handleViewBtn,
    currentPage,
    handlePageChange,
    employeeHoursData,
    rowsPerPage,
    handleRowsPerPageChange,
    totalPages,
  } = props;
  return (
    <div className="pto-table-container">
      <div className="pto-table-scroll">
        {tableDataArray.length > 0 ? (
          <table className="pto-table">
            <thead>
              <tr>
                {ptoStatus === "pending" && (
                  <>
                    {groupId === 5 || groupId === 8 ? null : (
                      <th style={{ width: "1%" }}>
                        {groupId === 5 || groupId === 8 ? null : (
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="me-0"
                              checked={
                                checkedValues.length > 0 &&
                                checkedValues.every((val) => val.isChecked)
                              }
                              onChange={handleMainCheckboxChange}
                            />
                          </div>
                        )}
                      </th>
                    )}
                  </>
                )}

                <th style={{ width: "15%", textAlign: "left" }}>
                  Employee Name
                </th>
                <th style={{ width: "13%", textAlign: "left" }}>
                  Regular hours
                </th>
                <th style={{ width: "10.5%", textAlign: "left" }}>
                  Pending hours
                </th>
                <th style={{ width: "10.5%", textAlign: "left" }}>Overtime</th>
                <th style={{ width: "10.5%", textAlign: "left" }}>
                  Time card status
                </th>
                <th style={{ width: "8%", textAlign: "left" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableDataArray &&
                tableDataArray.slice(startIndex, endIndex).map((row, index) => (
                  <tr key={`pending-${row.id || index}`}>
                    {ptoStatus === "pending" && (
                      <>
                        {groupId === 3 ? (
                          <td>
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="me-3"
                                checked={checkedValues[index]?.isChecked}
                                onChange={() =>
                                  handleIndividualCheckboxChange(index)
                                }
                              />
                            </div>
                          </td>
                        ) : groupId === 5 || groupId === 8 ? null : (
                          <td>
                            {/* {row.status === "-" ? null : ( */}
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="me-3"
                                checked={checkedValues[index]?.isChecked}
                                onChange={() =>
                                  handleIndividualCheckboxChange(index)
                                }
                              />
                            </div>
                            {/* )} */}
                          </td>
                        )}
                      </>
                    )}
                    <td>
                      {row?.name?.length > 15 ? (
                        <Tooltip
                          placement="top"
                          overlay={
                            <div style={{ wordBreak: "break-word" }}>
                              {row?.name}
                            </div>
                          }
                          overlayClassName="timeoff-calender-tooltip"
                        >
                          <div>
                            {row?.name?.substring(0, 15)}
                            {row?.name?.length > 15 ? "..." : ""}
                          </div>
                        </Tooltip>
                      ) : (
                        row.name
                      )}
                    </td>

                    <td>{row.total_hrs}</td>
                    <td>{row.pending_hrs}</td>
                    <td>{row.overtime_hrs}</td>
                    <td>
                      <div className="pending-wrapper">
                        {row.status === "-" ? null : (
                          <img
                            src={
                              row.status === "Pending"
                                ? PendingIcon
                                : row.status === "Approved"
                                ? ApproveIcon
                                : RejectIcon
                            }
                            alt="status-icon"
                          />
                        )}
                        <p>{row.status}</p>
                      </div>{" "}
                    </td>
                    <td>
                      <div className="approve_deny_btn">
                        <button onClick={() => handleViewBtn(row)}>VIEW</button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div className="no-data-text">
            <img src={WarningIcon} alt="warning" />
            <h5>
              {ptoStatus === "pending"
                ? "There are no pending time off request"
                : ptoStatus === "approved"
                ? "There are no approved time off request"
                : "There are no denied time off request"}
            </h5>
          </div>
        )}
      </div>
      {employeeHoursData.length > 10 && (
        <div className="d-flex justify-content-between py-3 px-3">
          <div>
            <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
            </select>
          </div>
          <div>
            {currentPage !== 0 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="non-active-pagination-btn"
              >
                &lt;
              </button>
            )}
            {Array.from({ length: totalPages }, (_, i) => i).map((page) => (
              <button
                key={page}
                disabled={currentPage === page}
                onClick={() => handlePageChange(page)}
                className={
                  currentPage === page
                    ? "active-pagination-btn "
                    : "non-active-pagination-btn"
                }
              >
                {page + 1}
              </button>
            ))}
            {currentPage !== totalPages - 1 && (
              <button
                disabled={currentPage === totalPages - 1}
                onClick={() => handlePageChange(currentPage + 1)}
                className="non-active-pagination-btn"
              >
                &gt;
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
